<template>
  <div>
    <div id="top-bar-one" class="text-center pt-7 pb-7">
      <v-container><Logo width="160" /> </v-container>
    </div>
    <v-container>Denne siden finnes ikke</v-container>
  </div>
</template>
<script>
import Logo from "../components/Logo";
export default {
  name: "NotFound",
  components: {
    Logo,
  },
  data() {
    return {
      null: null,
    };
  },
  created() {
    //
  },
  mounted() {
    //
  },
  methods: {
    //
  },
  computed: {
    //
  },
  watch: {},
};
</script>
<style lang="scss"></style>
